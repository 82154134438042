.menuMain .mainCallToAction {
  display: none;
}

@media (min-width: $nav-breakpoint) {
  .pageHeader .headerContent {
    height: 0;
    padding: 0;
    .mainCallToAction {
      display: none;
    }
  }

  .menuMain {
   padding: 40px;
  }
  li#menu3028a631-5b1f-4677-bb02-a7e6f6541670 {
    background: $brand-primary;
    border-radius: 30px;
    margin-left: 20px;
    text-transform: uppercase;
    a {
      color: white;
      padding: 15px 25px;
    }
    &:hover {
      background: darken($brand-primary, 10%);
    }
  }
}

.footerBox[class*="ewsletter"] > p {
  max-width: $container-max-width;
  margin: 0 auto 2rem;
  text-align: center;
}

.footerBox:not([class*="ewsletter"]) {
  //margin: 4rem 15px;
  h2 {
    margin-bottom: 2em;
  }
}

.footerBox2 {
  padding-right: 30%;
  img {
    width: 100%;
    height: auto;
    max-width: 250px;
  }
}

ul.footerLinks {
  li {
    display: block;
    margin: 20px 0;
    &:before {
      content: "\f0da";
      font-family: 'FontAwesome';
      margin: 0 20px 0 10px;
    }
  }
}

.footerBox4 {
  p {
    margin: 20px 0;
    &:before {
      font-family: 'FontAwesome';
      content: "\f095";
      margin: 0 20px 0 0;
    }
  }
  > a {
    &:before {
      font-family: 'FontAwesome';
      content: "\f003";
      margin: 0 20px 0 0;
    }
  }
}

